const INITIAL_STATE = {
  loadingTarget: true,
    target: [],
    error: false,
    error_msg: null,
  };
  
  export const target = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case "FETCH_TARGET_START":
       
        return {
          ...state,
          loadingTarget: true,
        };
      case "FETCH_TARGET_SUCCESS":
        

        return {
          ...state,
          loadingTarget: false,
          target: action.payload
        };
      case "FETCH_TARGET_FAIL":
        return {
          ...state,
          error: true,
          error_msg: action.payload,
          loadingTarget:false
        };
      case "DISMISS_ERROR_TARGET":
        return {
          ...state,
          error: false,
          error_msg: null,
        };
      default:
        return state;
    }
  };
  