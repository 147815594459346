import React, { Component } from "react";
import classNames from "classnames";
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { deleteSurvey } from "../store/actions/surveyList";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import { Link } from "react-router-dom"

// react component for creating dynamic tables
import "../styles/global.css";
import { Menu } from '../components/Dropdown/Dropdown';


import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

const colors = {
	green: '#4FB32C',
	yellow: '#F8BA00',
	global: '#00E4E4',
};

// const dataTable = [
//   ["Airi Satou", "Accountant", "Tokyo", "33"],
//   ["Angelica Ramos", "Chief Executive Officer (CEO)", "London", "47"],
//   ["Ashton Cox", "Junior Technical Author", "San Francisco", "66"],
//   ["Bradley Greer", "Software Engineer", "London", "41"],
//   ["Brenden Wagner", "Software Engineer", "San Francisco", "28"],
//   ["Brielle Williamson", "Integration Specialist", "New York", "61"],
//   ["Caesar Vance", "Pre-Sales Support", "New York", "21"],
//   ["Cedric Kelly", "Senior Javascript Developer", "Edinburgh", "22"],
//   ["Charde Marshall", "Regional Director", "San Francisco", "36"],
//   ["Colleen Hurst", "Javascript Developer", "San Francisco", "39"],
//   ["Dai Rios", "Personnel Lead", "Edinburgh", "35"],
//   ["Doris Wilder", "Sales Assistant", "Sidney", "23"],
//   ["Fiona Green", "Chief Operating Officer (COO)", "San Francisco", "48"],
//   ["Garrett Winters", "Accountant", "Tokyo", "63"],
//   ["Gavin Cortez", "Team Leader", "San Francisco", "22"],
//   ["Gavin Joyce", "Developer", "Edinburgh", "42"],
//   ["Gloria Little", "Systems Administrator", "New York", "59"],
//   ["Haley Kennedy", "Senior Marketing Designer", "London", "43"],
//   ["Herrod Chandler", "Sales Assistant", "San Francisco", "59"],
//   ["Hope Fuentes", "Secretary", "San Francisco", "41"],
//   ["Howard Hatfield", "Office Manager", "San Francisco", "51"],
//   ["Jackson Bradshaw", "Director", "New York", "65"],
//   ["Jena Gaines", "Office Manager", "London", "30"],
//   ["Jenette Caldwell", "Development Lead", "New York", "30"],
//   ["Jennifer Chang", "Regional Director", "Singapore", "28"],
//   ["Martena Mccray", "Post-Sales support", "Edinburgh", "46"],
//   ["Michael Silva", "Marketing Designer", "London", "66"],
//   ["Michelle House", "Integration Specialist", "Sidney", "37"],
//   ["Olivia Liang", "Support Engineer", "Singapore", "64"],
//   ["Paul Byrd", "Chief Financial Officer (CFO)", "New York", "64"],
//   ["Prescott Bartlett", "Technical Author", "London", "27"],
//   ["Quinn Flynn", "Support Lead", "Edinburgh", "22"],
//   ["Rhona Davidson", "Integration Specialist", "Tokyo", "55"],
//   ["Shou Itou", "Regional Marketing", "Tokyo", "20"],
//   ["Sonya Frost", "Software Engineer", "Edinburgh", "23"],
//   ["Suki Burks", "Developer", "London", "53"],
//   ["Tatyana Fitzpatrick", "Regional Director", "London", "19"],
//   ["Timothy Mooney", "Office Manager", "London", "37"],
//   ["Unity Butler", "Marketing Designer", "San Francisco", "47"],
//   ["Vivian Harrell", "Financial Controller", "San Francisco", "62"],
//   ["Yuri Berry", "Chief Marketing Officer (CMO)", "New York", "40"],
//   ["Tiger Nixon", "System Architect", "Edinburgh", "61"],
// ];
const dataTable = [] 
function ReactTables(props) {
  const Dispatch = useDispatch();
  const [alert, setAlert] = useState(null); 
  const [data, setData] = useState(
    props.Data.map((prop, key) => {

      return {
        id: prop.id,
        key: key,
        surveyId : prop.id,
        Serial: key+1,
        Survey_name: prop.survey_name,
        DateOfCreation: moment(prop.created_at).utc().format('YYYY-MM-DD'),
        welcome: prop.welcome_message,
        exit: prop.exit_message,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            {/* <Button
              onClick={() => {
                let obj = data.find((o) => o.id === key);
                alert(
                  "You've clicked LIKE button on \n{ \nName: " +
                    obj.name +
                    ", \nposition: " +
                    obj.position +
                    ", \noffice: " +
                    obj.office +
                    ", \nage: " +
                    obj.age +
                    "\n}."
                );
              }}
              color="info"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-heart-2" />
            </Button>{" "} */}
            {/* use this button to preview */}
               
            <a id="preview" target="_blank" rel="noreferrer" href={`https://dev.xane.ai/auths/web?survey_id=${prop.id}&token=${props.userToken}`}>
            <Button
               onClick={() => {
                  // document.getElementById('preview').style.color
              }}
              color="info"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="fas fa-eye" />
            </Button></a>{" "}

            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === (key));
                let pathname = props.history.location.pathname;
                props.history.push({
                 pathname: `/survey/edit/${prop.id}`,
                  state: { edit: true, id: prop.id },
                });

              }}
              color="warning"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                const  hideAlert = () => {
                  setAlert(
                    null
                  )
                };
                const successDelete = () => {
                setAlert(
                    
                      <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Deleted!"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="success"
                        btnSize=""
                      >
                        Survey has been deleted.
                      </ReactBSAlert>
                    )
                    var Data = data;
                Data.find((o, i) => {
                  if (o.key === key) {
                    Dispatch(deleteSurvey(o.id))
                    data.splice(i, 1);
                
                    return true;
                  }
                  return false;
                });
                setData(data);
                  
                };
                
                const warningWithConfirmMessage = () => {
                  setAlert(
                    
                       <ReactBSAlert
                         warning
                         style={{ display: "block", marginTop: "-100px" }}
                         title="Are you sure?"
                         onConfirm={() => successDelete()}
                         onCancel={() => hideAlert()}
                         confirmBtnBsStyle="success"
                         cancelBtnBsStyle="danger"
                         confirmBtnText="Yes, delete it!"
                         cancelBtnText="Cancel"
                         showCancel
                         btnSize=""
                       >
                         You will not be able to recover this Survey!
                       </ReactBSAlert>
                     )
                 
                 };
               
              
                  warningWithConfirmMessage()

              }}
              color="danger"
              size="sm"
              className={classNames("btn-icon btn-link like", {
                // "btn-neutral": key < 5,
              })}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>{" "}
          </div>
        ),
      };
    })
  );

  return (
    
    <>
      <div
        className="content"
        style={{
          paddingTop: "80px",
          paddingRight: "70px",
          paddingBottom: "30px",
          paddingLeft: "70px",
        }}
        data-testid="SurveyList-test"
      >
        {alert}
        <Row className="mt-5">
          <Col xs={12} md={12}>
            <Card>
              <CardHeader style={{
          background: '#262a31',
        }}>
                <CardTitle
                  tag="h2"
                  style={{
                    fontWeight: "bold",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ color: colors.global }}>YOUR SURVEYS</p>{" "}
                 
                </CardTitle>
               
              </CardHeader>
              <CardBody>
              <Button
                    className="btn-simple"
                    style={{
                      color: colors.global,
                      borderColor: colors.global,
                    }}
                    onClick={() => {
                      let path = props.history.location.pathname;
                      props.history.push("/survey-create");
                      
                    }}
                  >
                    <i className="fa fa-plus" /> Add Survey
                  </Button>
                <ReactTable
                  data={data}
                  filterable
                  resizable={false}
                  columns={[
                    {
                        Header: "Serial Number",
                        accessor: "Serial",
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "date of creation",
                        accessor: "DateOfCreation",
                        sortable: false,
                        filterable: false
                      },
                    {
                      Header: "Survey",
                      accessor: "Survey_name",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Welcome Message",
                      accessor: "welcome",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Exit Message",
                      accessor: "exit",
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e) => {
                        // IMPORTANT! React-Table uses onClick internally to trigger
                        // events like expanding SubComponents and pivots.
                        // By default a custom 'onClick' handler will override this functionality.
                        // If you want to fire the original onClick handler, call the
                        // 'handleOriginal' function.
                        // delete rowInfo.original.actions

				if (column.Header !== 'Actions') {
                        props.history.push({
                          pathname: `/survey/${rowInfo.original.surveyId}/questions-list`,
                          state: { id: rowInfo.original.surveyId, surveyName: rowInfo.original.Survey_name },
                        });
                      }
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ReactTables;
