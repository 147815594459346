import React from "react";
import { connect } from "react-redux";
import { signIn, signOut, dismissError } from "../store/actions/auth";
import Login from "../view/Login";
const LoginContainer = (props) => {
  return <Login {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  signIn: (username, password) => dispatch(signIn(username, password)),
  signOut: () => dispatch(signOut()),
  dismissError: () => dispatch(dismissError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);