import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { fetchThemes } from '../store/actions/themes'
import { fetchQuestion, getQuestionById } from '../store/actions/questionList'
import { Row, Col, Button, Label, Input, Spinner, FormGroup } from 'reactstrap';


import QuestionCreateView from "../view/QuestionCreateView";

const QuestionCreateContainer = (props) => {
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(() => {
		const token = localStorage.getItem("x-auth");
		return token !== null;
	});
  const [edit, setEdit] = useState(false)
  const Dispatch = useDispatch()
  const [routing, setRouting] = useState(null)

  const themes = useSelector(states=>states.themes)
  const question = useSelector(states=>states.questionList.question)
  const allQuestion = useSelector(states=>states.questionList.questionList)
  const work = props.history.location.state
 
  useEffect(()=>{
    if(isAuthenticated){
    const loadSpots = async ()=> {
      setLoading(true);
			await Dispatch(fetchQuestion(work.surveyid));
      // setAllQuestion(allQuestions)
      await Dispatch(fetchThemes())
      if(work.routing || work.edit ){
     await Dispatch(getQuestionById(work.questionId))
     setLoading(false)
      }
			setLoading(false)
    }
    loadSpots();
  }
  },[Dispatch, work.questionId,work.surveyid,isAuthenticated]);
  
  if(isAuthenticated===false){
    props.history.push("/login")
  
  }
  let surveyName = props.location.state.surveyName
 
if(work.edit){
  return(loading || question === null )?<div className="content" style={{ backgroundColor: '#19191A' }}>
	<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
</div>:	<QuestionCreateView {...props} themes={themes} work ={work} question={question} surveyName={surveyName}/>;
	
}
if(work.routing){
  return 	(loading || question === null)?<div className="content" style={{ backgroundColor: '#19191A' }}>
	<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
</div>:	<QuestionCreateView {...props} themes={themes} work ={work} allQuestion={allQuestion} question={question} surveyName={surveyName}/>;
}

if(work.create){
  return (themes.loadingthemes)? <div className="content" style={{ backgroundColor: '#19191A' }}>
	<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
</div>: <QuestionCreateView {...props} themes={themes} work ={work} surveyName={surveyName}/>;
}

};

export default QuestionCreateContainer;
