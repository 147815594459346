import { requestApi } from "../../config/apiHandler"
import { signOut } from '../actions/auth';

export const fetchThemesStart = () => {
	return {
		type: 'FETCH_THEMES_START',
	};
};

export const fetchThemesFail = (msg) => {
	return {
	  type: "FETCH_THEMES_FAIL",
	  payload: msg,
	};
  };
  
  export const dismissError = () => {
	return {
	  type: "DISMISS_ERROR_THEMES",
	};
  };
  

export const fetchThemes = () => {
	
	return async (dispatch) => {
		dispatch(fetchThemesStart());

		const response = await requestApi(`/themes`, 'GET', {});
		if (response.code == 'success') {
			
			dispatch({
				type: 'FETCH_THEMES_SUCCESS',
				payload: response.data
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	}  else {
		dispatch({
			type: 'FETCH_THEMES_FAIL',
			// payload: response.message
		});
};
}
}


