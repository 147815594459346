const initialState = {
    isLoading: true,
    isSignout: false,
    userToken: null,
    error: false,
    error_msg: null,
    role: null,
  };
  
  export const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case "RESTORE_TOKEN":
        return {
          ...state,
          userToken: action.payload.token,
          isLoading: false,
          role:action.payload.role
        };
      case "SIGN_IN_SUCCESS":
        return {
          ...state,
          isSignout: false,
          userToken: action.payload.token,
          role: action.payload.role,
          error: false,
          error_msg: null,
        };
      case "SIGN_IN_FAILURE":
        return {
          ...state,
          error: true,
          error_msg: action.payload,
        };
      case "SIGN_OUT":
        return {
          ...state,
          isSignout: true,
          userToken: null,
          role:null
        };
  
      case "LOGGED_OUT":
        return {
          ...state,
          isSignout: false,
        };
  
      case "DISMISS_ERROR":
        return {
          ...state,
          error: false,
          error_msg: null,
        };
  
      default:
        return state;
    }
  };
  