import { requestApi } from '../../config/apiHandler';
import { signOut } from '../actions/auth';


export const fetchSurveyStart = () => {
	return {
		type: 'FETCH_SURVEY_START',
	};
};

export const fetchSurvey = () => {
	
	return async (dispatch) => {
		dispatch(fetchSurveyStart());

		const response = await requestApi(`/surveys`, 'GET', {});
		if (response.code == 'success') {
			dispatch({
				type: 'FETCH_SURVEY_SUCCESS',
				payload: response.data,
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};

export const onError = (error) => {
	return {
		type: 'ON_ERROR',
		payload: error,
	};
};

export const dismissCmsError = () => {
	return {
		type: 'DISMISS_SURVEY_ERROR',
	};
};

export const postSurveyStart = () => {
	return {
		type: 'POST_SURVEY_START',
	};
};

export const putSurveyStart = () => {
	return {
		type: 'PUT_SURVEY_START',
	};
};

export const postSurvey = (Data) => {
	return async (dispatch) => {
		dispatch(postSurveyStart());
		
			const response = await requestApi('/surveys', 'POST', JSON.stringify(Data));
			if (response.code == 'success') {
				dispatch({
					type: 'POST_SURVEY_SUCCESS',
					payload: response.data.survey.id
				});
			} else if (response.code == 'authn_fail') {
				dispatch(signOut());
			} else {
				dispatch(onError(response.message));
			}
		
			}
		}
	

	// return {
	//   type: "POST_SURVEY_SUCCESS",
	// };


export const putSurvey = (data, id) => {
	return async (dispatch) => {
		dispatch(putSurveyStart());
        // JSON.stringify(data)
		// let params = JSON.parse(data);
		// params.id = id;

		//params = JSON.stringify

		const response = await requestApi(`/surveys/${id}`, 'PUT', JSON.stringify(data));
		if (response.code == 'success') {
			dispatch({
				type: 'PUT_SURVEY_SUCCESS',
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};

// return {
//   type: "POST_SURVEY_SUCCESS",
// };



export const getSurveyById = (id) => {
	return async (dispatch) => {
		dispatch({
			type: 'GET_SURVEY_BY_ID_START',
		});

		const response = await requestApi(`/surveys/${id}`, 'GET', {});
		if (response.code == 'success') {
			dispatch({
				type: 'GET_SURVEY_BY_ID_SUCCESS',
				payload: response.data,
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};

export const deleteSurvey = (id) => {
	return async (dispatch) => {
		dispatch({
			type: 'DELETE_SURVEY_START',
		});

		const response = await requestApi(`/surveys/${id}`, 'DELETE', {});
		if (response.code == 'success') {
			
			dispatch({
				type: 'DELETE_SURVEY_SUCCESS',
			});
		} else if (response.code == 'authn_fail') {
			dispatch(signOut());
		} else {
			dispatch(onError(response.message));
		}
	};
};
