import React from "react";
// reactstrap components
import {
  Input,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import '../../styles/DropDownBox.css'
class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      theme: "",
      questionState: "",
      themeState: ""
    };
  }
  

  componentDidMount() {
   
    if(this.props.editQuestion){
    this.setState({
      question: this.props.editQuestion,
      theme: this.props.editTheme,
      questionState: "has-success",
      themeState: "has-success"
    });
  }
}


  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
   
    switch (type) {
      case "length":
        if(stateName==="theme"){
        if (event.value) {
          this.setState({ [stateName + "State"]: "has-success" });
            this.setState({ [stateName]: event.value });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }}
        if(stateName==="question"){
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
            this.setState({ [stateName]: event.target.value });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }}
        break;
      default:
        break;
    }
   
  };
  isValidated = () => {

    if (
      (this.state.questionState === "has-success") && (this.state.themeState === "has-success")){
      return true;
    } else {
      if (this.state.questionState !== "has-success") {
        this.setState({ questionState: "has-danger" });
      }
      if (this.state.themeState !== "has-success") {
        this.setState({ themeState: "has-danger" });
      }
      return false;
    }
  };
  render() {
    var themeList = [];
 
    this.props.themes.themes.themes.forEach(function(element) {
      themeList.push({ label:element.theme, value: element.id })
  });
 
    return (
      <>
        <h4 className="info-text">
        Create Question
        </h4>
     
        <Row className="justify-content-center mt-3">
        <Label sm="0">Question</Label>
                      <Col sm="9">
                        <FormGroup className={this.state.questionState}>
                          <Input
                            name="question"
                            type="text"
                            placeholder="Enter Question Here"
                            defaultValue={this.state.question}
                            onChange={e =>
                              this.change(e, "question", "length", 1)
                            }
                          
                          />
                          {this.state.questionState === "has-danger" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col className="label-on-right" tag="label" sm="0">
                        <code></code>
                      </Col>
        </Row>
        <Row className="justify-content-center mt-2">
        <Label sm="0">Theme</Label>
        <Col lg="9" md="9" sm="9">
                        <FormGroup className={this.state.themeState}>
                        <Select
                        className="react-select info"
                        classNamePrefix="react-select"
                            name="theme"
                            options= { themeList }
                            defaultValue={themeList.find(obj => obj.value === this.props.editTheme)}
                            onChange={(e) => {
                              this.change(e, "theme", "length", 1)
                              }
                            }
                            // value={themeList.find(obj => obj.label === this.state.survey)}
                            value={themeList.find(
                            (obj) =>
                              obj.value ===
                              this.state.theme
                          )}
       
                            placeholder="Select Theme"
                          />
                          {this.state.themeState === "has-danger" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col className="label-on-right" tag="label" sm="0">
                        <code></code>
                      </Col>
        </Row>
      </>
    );
  }
}

export default Wizard;