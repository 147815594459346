/*!
=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import '../../styles/global.css'

// reactstrap components
import { FormGroup, Input, Row, Col, Label, Button } from "reactstrap";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
      // sequence
      // answer
      // follow_up
      // next_question_id
      question_answers: [
        {
          sequence: null,
          answer: "",
          score: "",
          follow_up: "",
          next_question_id: "",
        },
      ],
      question_answers_state: [
        {
          answer: "",
          follow_up: "",
          score: "",
          next_question_id: "",
        },
      ],
      subjective_question_answers: [
        {
          sequence: null,
          answer: "",
          follow_up: (this.props.routing===true)?this.props.question_answers[0].follow_up:"",
          next_question_id: (this.props.question_answers)?this.props.question_answers[0].next_question_id:"",
        },
      ],
      subjective_question_answers_state: [
        {
          follow_up: "",
          next_question_id: "",
        },
      ],
    };
  }

  componentDidMount() {
  
    if(this.props.routing===false){
    if (this.props.question_answers){
    if(this.props.editQuestionType==='subjective'){
      let data = this.state.subjective_question_answers;
      data[0].follow_up = this.props.question_answers[0].follow_up;
      if (data.sequence === null) data.sequence = null;
      this.setState({ subjective_question_answers: [ ...data] });


    let dataState = this.state.subjective_question_answers_state;
          dataState[0].follow_up = "has-success";
          this.setState({
            subjective_question_answers_state: [ ...dataState ],
    })
  }
  else{
    let data = []
    let dataState=[]
   
   
    this.props.question_answers.forEach((prop) => {
        data.push({
          sequence: prop.sequence,
          answer: prop.answer,
          score: prop.score,
          follow_up: prop.follow_up,
          // next_question_id: (prop.next_question_id)?prop.next_question_id:""
        });
       
      })
    this.setState({ question_answers: [...data] });
   
    this.props.question_answers.forEach((prop) => {
      dataState.push({
        answer: "has-success",
        follow_up: "has-success",
        score: "has-success",
      });
    })
  this.setState({ question_answers_state: [...dataState] });


  }


}
    }
    else{
      
     
      let data = []
      let dataState=[]
      this.props.question_answers.forEach((prop) => {
          data.push({
            sequence: prop.sequence,
            answer: prop.answer,
            score: prop.score,
            follow_up: prop.follow_up,
            next_question_id: (prop.next_question_id)?prop.next_question_id:""
          });
          
        })
      this.setState({ question_answers: [...data] });
     
      this.props.question_answers.forEach((prop) => {
        dataState.push({
          answer: "has-success",
          score: "has-success",
          follow_up: "has-success",
        });
      })
    this.setState({ question_answers_state: [...dataState] });
    }
}


  handleRemoveClick = (index) => {
    let data = this.state.question_answers;
    data.splice(index, 1);
    this.setState({ question_answers: [...data] });
    let dataState = this.state.question_answers_state;
    dataState.splice(index, 1);
    this.setState({ question_answers_state: [...dataState] });
  };

  handleAddClick = () => {
    let data = this.state.question_answers;
    this.setState({
      question_answers: [
        ...data,
        {
          sequence: null,
          answer: "",
          score: "",
          follow_up: "",
          next_question_id: "",
        },
      ],
    });
    let dataState = this.state.question_answers_state;
    this.setState({
      question_answers_state: [
        ...dataState,
        {
          answer: "",
          follow_up: "",
          score: "",
          next_question_id: "",
        },
      ],
    });
  };
  renderSwitch(param,nextQuestionList, question) {
  
    let routing = this.props.routing
   
    switch (param) {
      case "objective":
        return (
          <>
            <Row className="justify-content-center mt-2">
              <h4 className="info-text">
                {" "}
                {question} 
                {/* <i className="fas fa-question"></i>{" "} */}
              </h4>
            </Row>
            <Row className="justify-content-center">
              <Col sm="12">
                <h4 className="info-text">
                  Enter Answer Options <i className="fas fa-bullseye"></i>
                </h4>
              </Col>
            </Row>
            {this.state.question_answers.map((x, i) => {
              return (
                <Row className="ml-5 mt-2" key={i}>
                  <Label sm="0">
                    <i className="fas fa-bullseye"></i>
                  </Label>
                  <Col sm="3">
                    <FormGroup
                      className={this.state.question_answers_state[i].answer}
                    >
                      <Input
                        name="answer"
                        type="text"
                        disabled={(routing?("true"):(false))}
                        placeholder="Enter Option Text"
                        defaultValue={this.state.question_answers[i].answer}
                        onChange={(e) => this.change(e, i, "length", 1)}
                      />
                      {this.state.question_answers_state[i].answer ===
                      "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup
                      className={this.state.question_answers_state[i].score}
                    >
                      <Input
                      // style={{padding:'5px 5px'}}
                        name="score"
                        type="number"
                        disabled={(routing?("true"):(false))}
                        placeholder="Score"
                        defaultValue={this.state.question_answers[i].score}
                        onChange={(e) => this.change(e, i, "length", 1)}
                      />
                      {this.state.question_answers_state[i].score ===
                      "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col sm="3">
                  <FormGroup
                      className={this.state.question_answers_state[i].follow_up}
                    >
                    <Input
                      name="follow_up"
                      type="text"
                      placeholder={(routing?("No Follow Up Text"):"Enter Follow Up Text")}
                      disabled={(routing?("true"):(false))}
                      defaultValue={
                        this.state.question_answers[i].follow_up
                      }
                      onChange={(e) => this.change(e, i, "length", 1)}
                    />
                    {this.state.question_answers_state[i].follow_up ===
                    "has-danger" ? (
                      <label className="error">This field is required.</label>
                    ) : null}
                    </FormGroup>
                  </Col>
                  {routing ? (
                    <>
                      <Col lg="3" md="6" sm="3">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="next_question_id"
                          options={nextQuestionList}
                          value={nextQuestionList.find(
                            (obj) =>
                              obj.value ===
                              this.state.question_answers[i].next_question_id
                          )
                        }
                          onChange={(e) => {
                            let event = {};
                            event.target = e;
                            event.target.name = "next_question_id";
                            this.change(event, i, "length", 1);
                          }}
                          placeholder="Select next question"
                        />
                      </Col>
                    </>
                  ) : null}
 {!routing ?( <>
                  {this.state.question_answers.length - 1 === i && (
                    <Button
                      color={this.props.addBtnColor}
                      className="btn-round btn-icon"
                      onClick={() => this.handleAddClick()}
                      // disabled= {!this.state.linked_to_pdf[i]?"true":""}
                      disabled={/*view ||*/ !this.state.question_answers[i]}
                      style={{
                        color: "rgb(248, 186, 0)",
                        borderColor: "rgb(248, 186, 0)",
                        fontSize: 12,
                        display: "flex",
                        width: 100,
                        height: 30,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="tim-icons icon-simple-add"></i>
                        <span style={{ marginLeft: 5 }}></span>
                      </div>
                    </Button>
                  )}
                  {this.state.question_answers.length !== 1 &&
                    this.state.question_answers.length - 1 === i && (
                      <Button
                        color={this.props.removeBtnColor}
                        className="btn-round btn-icon"
                        onClick={() => this.handleRemoveClick(i)}
                        // disabled={this.props.disabled}
                        disabled={/*view ||*/ !this.state.question_answers[i]}
                        style={{
                          color: "rgb(248, 186, 0)",
                          borderColor: "rgb(248, 186, 0)",
                          fontSize: 12,
                          display: "flex",
                          width: 100,
                          height: 30,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <i className="tim-icons icon-simple-delete"></i>{" "}
                          <span style={{ marginLeft: 5 }}></span>
                        </div>
                      </Button>
                    )}</>):null}
                </Row>
              );
            })}
          </>
        );
      case "subjective":
        return (
          <>
            <Row className="justify-content-center mt-2">
              <h4 className="info-text">
                {" "}
                {question} 
                {/* <i className="fas fa-question"></i>{" "} */}
              </h4>
            </Row>

            <Row className="justify-content-center mt-2">
            <Col sm="5">
                  <FormGroup
                      className={this.state.subjective_question_answers_state[0].follow_up}
                    >
                <Input
                  name="follow_up"
                  type="text"
                  placeholder={(routing?("No Follow Up Text"):"Enter Follow Up Text")}
                  disabled={(routing?("true"):(false))}
                  defaultValue={
                    this.state.subjective_question_answers[0].follow_up
                  }
                  onChange={(e) => this.subjectiveChange(e, "length", 1)}
                />
                {this.state.subjective_question_answers_state[0].follow_up ===
                "has-danger" ? (
                  <label className="error">This field is required.</label>
                ) : null}
                </FormGroup>
              </Col>
              {routing > 0 ? (
                <>
                  <Col lg="3" md="6" sm="3">
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="next_question_id"
                      options={nextQuestionList}
                      onChange={(e) => {
                        let event = {};
                        event.target = e;
                        event.target.name = "next_question_id";
                        this.subjectiveChange(event,"length", 1);
                      }}
                      value={nextQuestionList.find(
                        (obj) =>
                          obj.value ===
                          this.state.subjective_question_answers[0].next_question_id
                      )
                    }
                      placeholder="Select next question"
                    />
                  </Col>
                </>
              ) : null}
            </Row>
          </>
        );
      case "multiple":
        return (
          <>
            <Row className="justify-content-center mt-2">
              <h4 className="info-text">
                {" "}
                {question}
                 {/* <i className="fas fa-question"></i>{" "} */}
              </h4>
            </Row>
            <Row className="justify-content-center">
              <Col sm="12">
                <h4 className="info-text">
                  Enter Answer Options <i className="far fa-check-square"></i>
                </h4>
              </Col>
            </Row>
            {this.state.question_answers.map((x, i) => {
              return (
                <Row className="ml-5 mt-2" key={i}>
                  <Label sm="0">
                    <i className="far fa-check-square"></i>
                  </Label>
                  <Col sm="4">
                    <FormGroup
                      className={this.state.question_answers_state[i].answer}
                    >
                      <Input
                        name="answer"
                        type="text"
                        disabled={(routing?("true"):(false))}
                        placeholder="Enter answer Text"
                        defaultValue={this.state.question_answers[i].answer}
                        onChange={(e) => this.change(e, i, "length", 1)}
                      />
                      {this.state.question_answers_state[i].answer ===
                      "has-danger" ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col sm="4">
                  <FormGroup
                      className={this.state.question_answers_state[i].follow_up}
                    >
                    <Input
                      name="follow_up"
                      type="text"
                      placeholder={(routing?("No Follow Up Text"):"Enter Follow Up Text")}
                      disabled={(routing?("true"):(false))}
                      defaultValue={
                        this.state.question_answers[i].follow_up
                      }
                      onChange={(e) => this.change(e, i, "length", 1)}
                    />
                    {this.state.question_answers_state[i].follow_up ===
                    "has-danger" ? (
                      <label className="error">This field is required.</label>
                    ) : null}
                    </FormGroup>
                  </Col>
                  {routing ? (
                    <>
                      <Col lg="3" md="6" sm="3">
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="next_question_id"
                          options={nextQuestionList}
                          onChange={(e) => {
                            let event = {};
                            event.target = e;
                            event.target.name = "next_question_id";
                            this.change(event, i, "length", 1);
                          }}
                          value={nextQuestionList.find(
                            (obj) =>
                              obj.value ===
                              this.state.question_answers[i].next_question_id
                          )}
                          placeholder="Select next question"
                        />
                      </Col>
                    </>
                  ) : null}
                  {/* <Button className="btn-round btn-icon" color="primary">
                    <i className="tim-icons icon-heart-2" />
                  </Button> */}
                 {!routing? ( <> {this.state.question_answers.length - 1 === i && (
                    <Button
                      color={this.props.addBtnColor}
                      className="btn-round btn-icon"
                      onClick={() => this.handleAddClick()}
                      // disabled= {!this.state.linked_to_pdf[i]?"true":""}
                      disabled={/*view ||*/ !this.state.question_answers[i]}
                      style={{
                        color: "rgb(248, 186, 0)",
                        borderColor: "rgb(248, 186, 0)",
                        fontSize: 12,
                        display: "flex",
                        width: 100,
                        height: 30,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="tim-icons icon-simple-add"></i>
                        <span style={{ marginLeft: 5 }}></span>
                      </div>
                    </Button>
                  )}
                  {this.state.question_answers.length !== 1 &&
                    this.state.question_answers.length - 1 === i && (
                      <Button
                        color={this.props.removeBtnColor}
                        className="btn-round btn-icon"
                        onClick={() => this.handleRemoveClick(i)}
                        // disabled={this.props.disabled}
                        disabled={/*view ||*/ !this.state.question_answers[i]}
                        style={{
                          color: "rgb(248, 186, 0)",
                          borderColor: "rgb(248, 186, 0)",
                          fontSize: 12,
                          display: "flex",
                          width: 100,
                          height: 30,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <i className="tim-icons icon-simple-delete"></i>{" "}
                          <span style={{ marginLeft: 5 }}></span>
                        </div>
                      </Button>
                    )}</>): null}
                </Row>
              );
            })}
          </>
        );
      default:
        return <h5 className="info-text">Nothing Left</h5>;
    }
  }

  verifyLength = (value, length, name) => {
    console.log()
    if(name==="follow_up"){
      return true
    }
    if(name==='next_question_id')
    return true
    if(value.length >= length) {
     console.log(value.length)
      return true;
    }
    return false;
  };

  // this.subjectiveChange(e,"length", 1)
  subjectiveChange = (event, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo, event.target.name)) {

          // this.setState({ [stateName + "State"]: "has-success" });
          let dataState = this.state.subjective_question_answers_state;
          dataState[0][event.target.name] = "has-success";
          this.setState({
            subjective_question_answers_state: [ ...dataState ],
          });

          let data = this.state.subjective_question_answers;
          data[0][event.target.name] = event.target.value;
          if (data.sequence === null) data.sequence = null;
          this.setState({ subjective_question_answers: [ ...data] });
        } else {
          let dataState = this.state.subjective_question_answers_state;
          dataState[0][event.target.name] = "has-danger";
          this.setState({ subjective_question_answers_state: [...dataState] });
        }
        break;
        default:
          break;
        }
        //this.setState({ [stateName]: event.target.value });
      };
      // this.change(e, i, "length", 1)
      change = (event, index, type, stateNameEqualTo, maxValue) => {
  
        switch (type) {
          case "length":
            if (this.verifyLength(event.target.value, stateNameEqualTo, event.target.name)) {
              
              // this.setState({ [stateName + "State"]: "has-success" });
              let dataState = this.state.question_answers_state;
              dataState[index][event.target.name] = "has-success";
              this.setState({ question_answers_state: [...dataState] });
              
              let data = this.state.question_answers;
              data[index][event.target.name] = event.target.value;
              if (data[index].sequence === null) data[index].sequence = index + 1;
              this.setState({ question_answers: [...data] });
            } else {
              let dataState = this.state.question_answers_state;
              dataState[index][event.target.name] = "has-danger";
              this.setState({ question_answers_state: [...dataState] });
            }
          
            
        break;
      default:
        break;
    }
    //this.setState({ [stateName]: event.target.value });
  };

  isValidated = () => {
 
    let QuestionType 
    let error = false;
    if(this.props.routing){
      QuestionType = this.props.editQuestionType.toLowerCase()
      return true
    }
    else{

      QuestionType = JSON.parse(localStorage.getItem("question_type"));
    }
    if (QuestionType === "subjective") {
      if (
       
        this.state.subjective_question_answers_state[0].follow_up !== "has-success"
        // this.state.subjective_question_answers.next_question_id === "has-danger"
      ) {
        let dataState = this.state.subjective_question_answers_state;
        dataState[0].follow_up = "has-danger";
        this.setState({ subjective_question_answers_state: [...dataState] });
        return false;
      }
    } else {
      let value
      this.state.question_answers_state.map((x, i) => {
        console.log( JSON.parse(localStorage.getItem("question_type"))!=="multiple",localStorage.getItem("question_type"),"multiple" )
        if (
          (x.answer !== "has-success" && (value = "answer")) 
          || (JSON.parse(localStorage.getItem("question_type"))!=="multiple" && x.score !== "has-success" && (value = "score")) 
          // || (x.follow_up !== "has-success" && (value = "follow_up")) it is commented out because followUP text is not mendatory
        ) {
          console.log(x)
          let dataState = this.state.question_answers_state;
              dataState[i][value] = "has-danger";
              this.setState({ question_answers_state: [...dataState] });
          error = true;
          return error;
        }
      });
    }
    console.log(error)
    if (!error) return true;
    else return false;
  };

  render() {
 
var nextQuestionList = [
  {
    value: "",
    label: "",
  },
];
if(this.props.routing){
    let allQuestion = this.props.allQuestions;
    if (allQuestion.length <= 0) {
       nextQuestionList = [
        {
          value: "",
          label: "",
        },
      ];
    } else nextQuestionList = [];
    allQuestion.forEach((prop) => {
     
      if(this.props.question.id !== prop.id && prop.active !== 0){
        nextQuestionList.push({
          value: prop.id,
          label: prop.question,
        });

      }
    })}
  
    var question
    if (this.props.wizardData.Question) {
       question = this.props.wizardData.Question.question;
    }
    else{
       question = (this.props.routing)?this.props.question.question:null
    }
    
    let qt
    if(this.props.routing){
      if(this.props.editQuestionType.toLowerCase() === 'subjective')
      {
      
      qt = 'subjective'
      }
      if(this.props.editQuestionType.toLowerCase() === 'objective'){

      
        qt ='objective'
      }
      if(this.props.question.question_type.toLowerCase()==='multiple choice'){
    
        qt = 'multiple'
        // console.log("if(multiple)",qt)
        
        
      }
    }
   
    return (
      <>
        <form>
          <Row className="justify-content-center">
            <Col sm="12">
              {!this.props.routing || JSON.parse(localStorage.getItem("question_type"))
                ? this.renderSwitch(
                    JSON.parse(localStorage.getItem("question_type")),
                    nextQuestionList,
                    question
                  )
                : this.renderSwitch(
                  qt,
                  nextQuestionList,
                  question
                )
                }
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Wizard;
