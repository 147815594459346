import React from "react";
// reactstrap components
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
  
    
    this.state = {
      // answerType: (JSON.parse(localStorage.getItem('answer_type')))?JSON.parse(localStorage.getItem('answer_type')):""
      questionType: this.props.questionType
    };
    this.onValueChange = this.onValueChange.bind(this);
   
   
    
  }
  componentWillMount() {
   
    if(this.props.editQuestionType){
      localStorage.setItem("question_type", JSON.stringify(this.props.editQuestionType));
  }
  else {
    localStorage.setItem("question_type", JSON.stringify("objective"));
  }
}
  onValueChange(event) {
          localStorage.setItem('question_type',JSON.stringify(event.target.value))
    this.setState({
      [event.target.name]: event.target.value
    });  
  }
    
  render() {
    return (
      <>
        <h4 className="info-text">Question Type</h4>
        <Row className="justify-content-center">
          <Col className="text-center" lg="4" >
          <FormGroup check className="form-check-radio" style={{textAlign:"left"}}>
                          <Label check>
                            <Input
                             defaultChecked= {(this.props.questionType==='objective')?true :false}
                              defaultValue="objective"
                              id="exampleRadios1"
                              name="questionType"
                              type="radio"
                              // disabled= {"true"}
                              onChange={this.onValueChange}
                              value="objective"
                            />
                            <span className="form-check-sign" />
                            Objective
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" style={{textAlign:"left"}}>
                          <Label check>
                            <Input
                               defaultChecked= {(this.props.questionType==='subjective')?true :false}
                               defaultValue="subjective"
                               id="exampleRadios2"
                              name="questionType"
                              type="radio"
                              onChange={this.onValueChange}
                            />
                            <span className="form-check-sign" />
                            Subjective
                          </Label>
                        </FormGroup>
                        <FormGroup check className="form-check-radio" style={{textAlign:"left"}}>
                          <Label check>
                            <Input
                              defaultChecked= {(this.props.questionType==='multiple')?true :false}
                              defaultValue="multiple"
                              id="exampleRadios2"
                              name="questionType"
                              type="radio"
                              onChange={this.onValueChange}
                            />
                            <span className="form-check-sign" />
                            Multiple
                          </Label>
                        </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Wizard;