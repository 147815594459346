const INITIALSTATE = {
  loading: false,
  questionList: [],
  error: false,
  question: null,
  error_msg: null,
  fetchingDocument: false,
  uploading: false,
  showModal: false,
  Routing: false,
  deleting: false,
};

export const questionList = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case "FETCH_QUESTION_START":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_QUESTION_SUCCESS":
      return {
        ...state,
        questionList: action.payload,
        loading: false,
      };
    case "ON_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        uploading: false,
        showModal: false,
      };
    case "DISMISS_QUESTION_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "POST_QUESTION_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "POST_QUESTION_SUCCESS":
      return {
        ...state,
        uploading: false,
      };
    case "PUT_QUESTION_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "PUT_QUESTION_SUCCESS":
      return {
        ...state,
        uploading: false,
      };
    case "GET_QUESTION_BY_ID_START":
      return {
        ...state,
        fetchingDocument: true,
      };
    case "GET_QUESTION_BY_ID_SUCCESS":
      return {
        ...state,
        question: action.payload.question,
        fetchingDocument: false,
      };
      case "PUT_QUESTION_ROUTING_START":
      return {
        ...state,
       Routing: true,
     
      };
    case "PUT_QUESTION_ROUTING_SUCCESS":
      return {
        ...state,
        Routing: false,
      };
      case "DELETE_QUESTION_START":
        return {
          ...state,
          deleting: true,
        };
        case "DELETE_QUESTION_SUCCESS":
        return {
          ...state,
          deleting: false,
        };
    default:
      return state;
  }
};
