import { requestApi } from "../../config/apiHandler"
import { signOut } from '../actions/auth';

export const fetchInterfaceStart = () => {
	return {
		type: 'FETCH_INTERFACE_START',
	};
};

export const fetchInterfaceFail = (msg) => {
	return {
	  type: "FETCH_INTERFACE_FAIL",
	  payload: msg,
	};
  };
  
  export const dismissError = () => {
	return {
	  type: "DISMISS_ERROR_INTERFACE",
	};
  };
  

export const fetchInterface = () => {
	
	return async (dispatch) => {
		dispatch(fetchInterfaceStart());

		const response = await requestApi(`/meta`, 'GET', {});
		if (response.code == 'success') {
			
			dispatch({
				type: 'FETCH_INTERFACE_SUCCESS',
				payload: response.data
			});
	}  else if (response.code == 'authn_fail') {
		dispatch(signOut());

	} else {
		dispatch({
			type: 'FETCH_INTERFACE_FAIL',
			payload: response.message
		});
};
}
}


