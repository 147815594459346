import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import  { surveyList }  from '../store/reducers/surveyList';
import  { themes }  from '../store/reducers/themes';
import  { target }  from '../store/reducers/target';
import  { interfaceList }  from '../store/reducers/InterfaceList';
import  { questionList }  from '../store/reducers/questionList';
import  { template }  from '../store/reducers/template';
import { authReducer } from './reducers/auth';
// import { authReducer } from './reducers/auth';
// import { spinner } from './reducers/spinner';
// import { meta } from './reducers/meta';
// import { features } from './reducers/features';
// import { parts } from './reducers/parts';

const rootReducer = combineReducers({
	surveyList,
	themes,
	target,
	interfaceList,
	questionList,
	template,
	auth: authReducer,

});

const composeEnhancers =
	(process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

export const configureStore = () => {
	return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};