import { requestApi } from "../../config/apiHandler"
import { signOut } from '../actions/auth';

export const fetchTemplateStart = () => {
	return {
		type: 'FETCH_TEMPLATE_START',
	};
};

export const fetchTemplateFail = (msg) => {
	return {
	  type: "FETCH_TEMPLATE_FAIL",
	  payload: msg,
	};
  };
  
  export const dismissError = () => {
	return {
	  type: "DISMISS_ERROR_TARGET",
	};
  };
  

export const fetchTemplate = () => {
	
	return async (dispatch) => {
		dispatch(fetchTemplateStart());

		const response = await requestApi(`/templates`, 'GET', {});
		if (response.code == 'success') {
			
			dispatch({
				type: 'FETCH_TEMPLATE_SUCCESS',
				payload: response.data
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	} else {
		dispatch({
			type: 'FETCH_TEMPLATE_FAIL',
			payload: response.message
		});
};
}
}

export const postTemplateStart = () => {
	return {
		type: 'POST_TEMPLATE_START',
	};
};

export const postTemplateFail = (msg) => {
	return {
	  type: "POST_TEMPLATE_FAIL",
	  payload: msg,
	};
  };
export const postTemplate = (Data) => {
	
	return async (dispatch) => {
		dispatch(postTemplateStart());

		const response = await requestApi(`/templates`, 'POST', JSON.stringify(Data));
		if (response.code == 'success') {
			
			dispatch({
				type: 'POST_TEMPLATE_SUCCESS',
				payload: response.data
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	} else {
		dispatch({
			type: 'POST_TEMPLATE_FAIL',
			payload: response.message
		});
};
}
}

export const putTemplateStart = () => {
	return {
		type: 'PUT_TEMPLATE_START',
	};
};

export const putTemplateFail = (msg) => {
	return {
	  type: "PUT_TEMPLATE_FAIL",
	  payload: msg,
	};
  };
export const putTemplate = (id, Data) => {
	
	return async (dispatch) => {
		dispatch(putTemplateStart());

		const response = await requestApi(`/templates/${id}`, 'PUT',JSON.stringify(Data));
		if (response.code == 'success') {
			
			dispatch({
				type: 'PUT_TEMPLATE_SUCCESS',
				payload: response.data
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	} else {
		dispatch({
			type: 'PUT_TEMPLATE_FAIL',
			payload: response.message
		});
};
}
}


