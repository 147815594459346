import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { configureStore } from './store/store';

import App from './containers/App';

import './assets/css/nucleo-icons.css';
import 'react-notification-alert/dist/animate.css';
import './assets/css/black-dashboard-pro-react.css';
import './styles/styles.css';

const hist = createBrowserHistory();
export const store = configureStore();	

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter history={hist}>
			<App hist={hist} />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
