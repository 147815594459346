import { requestApi } from "../../config/apiHandler"
import { signOut } from '../actions/auth';

export const fetchTargetStart = () => {
	return {
		type: 'FETCH_TARGET_START',
	};
};

export const fetchTargetFail = (msg) => {
	return {
	  type: "FETCH_TARGET_FAIL",
	  payload: msg,
	};
  };
  
  export const dismissError = () => {
	return {
	  type: "DISMISS_ERROR_TARGET",
	};
  };
  

export const fetchTarget = () => {
	
	return async (dispatch) => {
		dispatch(fetchTargetStart());
		const response = await requestApi(`/targets`, 'GET', {});
		if (response.code == 'success') {
			console.log(response.data)
			dispatch({
				type: 'FETCH_TARGET_SUCCESS',
				payload: response.data
			});
	} else if (response.code == 'authn_fail') {
		dispatch(signOut());
	} else {
		dispatch({
			type: 'FETCH_TARGET_FAIL',
			payload: response.message
		});
};
}
}


