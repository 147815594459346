const INITIAL_STATE = {
    loadingthemes: true,
      themes: [],
      error: false,
      error_msg: null,
    };
    
    export const themes = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case "FETCH_THEMES_START":
         
          return {
            ...state,
            loadingthemes: true,
          };
        case "FETCH_THEMES_SUCCESS":
          
  
          return {
            ...state,
            loadingthemes: false,
            themes: action.payload
          };
        case "FETCH_THEMES_FAIL":
          return {
            ...state,
            error: true,
            error_msg: action.payload,
            loadingthemes:false
          };
        case "DISMISS_ERROR_THEMES":
          return {
            ...state,
            error: false,
            error_msg: null,
          };
        default:
          return state;
      }
    };
    