import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap';
import { fetchThemes } from '../store/actions/themes'
import { fetchQuestion } from '../store/actions/questionList'


import QuestionList from '../view/QuestionList';


const QuestionListContainer = (props) => {
	const [loading, setLoading] = useState(true)
	const [isAuthenticated, setAuthenticated] = useState(() => {
		const token = localStorage.getItem("x-auth");
		return token !== null;
	});
	const Dispatch = useDispatch();
	
	const surveyId = props.history.location.state.id
	// const survey = DataTable.find((a) => a.id == surveyId)

	useEffect(()=>{
		if(isAuthenticated){
		const loadSpots = async ()=> {
			setLoading(true);
			await Dispatch(fetchQuestion(surveyId));
		 await Dispatch(fetchThemes())
			setLoading(false)
			}
			loadSpots();
		}
		}, [Dispatch,surveyId,isAuthenticated]);
		const questionList = useSelector((state) => state.questionList)
	const themes = useSelector((state) => state.themes)
		if(!loading){

			var data = questionList.questionList.questions.filter(e => e.active !== 0)
		}
		if(isAuthenticated===false){
			props.history.push("/login")
		
		}

		return (loading)? <div className="content" style={{ backgroundColor: '#19191A' }}>
		<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
	</div>: <QuestionList {...props} surveyId = {surveyId} Data = {data} themes = {themes}/>;

};



export default QuestionListContainer;