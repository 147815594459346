const INITIALSTATE = {
  loading: false,
  surveyList: [],
  error: false,
  survey: null,
  recent_survey_id: null,
  error_msg: null,
  fetchingDocument: false,
  uploading: false,
  showModal: false,
  deleting: false,
};

export const surveyList = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case "FETCH_SURVEY_START":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_SURVEY_SUCCESS":
      return {
        ...state,
        surveyList: action.payload,
        loading: false,
      };
    case "ON_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        uploading: false,
        showModal: false,
      };
    case "DISMISS_SURVEY_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "POST_SURVEY_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "POST_SURVEY_SUCCESS":
      return {
        ...state,
        uploading: false,
        recent_survey_id: action.payload
      };
    case "PUT_SURVEY_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "PUT_SURVEY_SUCCESS":
      return {
        ...state,
        uploading: false,
      };
      case "GET_SURVEY_BY_ID_START":
        return {
          ...state,
          fetchingDocument: true,
        };
        case "GET_SURVEY_BY_ID_SUCCESS":
    
      return {
        ...state,
        survey: action.payload,
        fetchingDocument: false,
      };
      
      case "DELETE_SURVEY_START":
        return {
          ...state,
          deleting: true,
        };
        case "DELETE_SURVEY_SUCCESS":
        return {
          ...state,
          deleting: false,
        };
    default:
      return state;
  }
};
