const INITIAL_STATE = {
    loadingTemplate: true,
    postTemplateLoading: true,
      template: [],
      saveTemplate: null,
      error: false,
      error_msg: null,
    };
    
    export const template = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case "FETCH_TEMPLATE_START":
         
          return {
            ...state,
            loadingTemplate: true,
          };
        case "FETCH_TEMPLATE_SUCCESS":
          
  
          return {
            ...state,
            loadingTemplate: false,
            template: action.payload
          };
        case "FETCH_TEMPLATE_FAIL":
          return {
            ...state,
            error: true,
            error_msg: action.payload,
            loadingTemplate:false
          };
        case "DISMISS_ERROR_TARGET":
          return {
            ...state,
            error: false,
            error_msg: null,
          };
        case "POST_TEMPLATE_START":
          return {
            ...state,
            postTemplateLoading: true,
            saveTemplate: null
          };
          case "POST_TEMPLATE_SUCCESS":
            return {
              ...state,
              postTemplateLoading: false,
              saveTemplate: {...action.payload}
            };
            case "POST_TEMPLATE_FAIL":
              return {
                ...state,
                error: true,
                error_msg: action.payload,
                postTemplateLoading:false
              };
        case "PUT_TEMPLATE_START":
          return {
            ...state,
            putTemplateLoading: true,
          };
          case "PUT_TEMPLATE_SUCCESS":
            return {
              ...state,
              putTemplateLoading: false,
              saveTemplate: {...action.payload}
            };
            case "PUT_TEMPLATE_FAIL":
              return {
                ...state,
                error: true,
                error_msg: action.payload,
                putTemplateLoading:false
              };
         
        default:
          return state;
      }
    };
    