import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Label, Input, Spinner, FormGroup } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux'
import SurveyCreateView from '../view/SurveyCreateView';
import { fetchTarget } from '../store/actions/target'
import { fetchInterface } from '../store/actions/interface'
import { ErrorAlert } from '../components/Alert/ErrorAlert';
import { fetchTargetFail, dismissError } from "../store/actions/target";
import { getSurveyById } from "../store/actions/surveyList";


const SurveyCreateContainer = (props) => {
	const [isAuthenticated, setAuthenticated] = useState(() => {
		const token = localStorage.getItem("x-auth");
		return token !== null;
	});
	// props.location.state
	const surveyList = useSelector(state => state.surveyList);
	const [edit, setEdit] = useState(false)
	const [survey, SetSurvey] = useState(null)
	const Dispatch = useDispatch();
	useEffect(()=>{
		if(isAuthenticated){
		Dispatch(fetchTarget())
		Dispatch(fetchInterface())
		if(props.history.location.state){
		let id = props.history.location.state.id

		setEdit(true)
		Dispatch(getSurveyById(id))
		}
	}
	},[isAuthenticated])
	const target = useSelector((state) => state.target)
	const interfaceList = useSelector((state) => state.interfaceList)
	const editSurvey = useSelector((state) => state.surveyList)

	if(isAuthenticated===false){
		props.history.push("/login")
		
	}
if(!edit){
	

	return (target.loadingTarget || interfaceList.loadingINTERFACE)? <div className="content" style={{ backgroundColor: '#19191A' }}>
	<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
</div>:(target.error)? (
          <ErrorAlert message={target.error_msg} hide={() => Dispatch(dismissError())} />
        ):<SurveyCreateView {...props} target={target} interface={interfaceList.meta.survey_types}/>

}else{

	return(target.loadingTarget || interfaceList.loadingINTERFACE || editSurvey.fetchingDocument)?<div className="content" style={{ backgroundColor: '#19191A' }}>
	<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" size="lg" />
</div>:	<SurveyCreateView {...props} survey={editSurvey.survey.survey} target={target} interface={interfaceList.meta.survey_types}/>;
}
};

export default SurveyCreateContainer;